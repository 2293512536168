<template>
  <base-layout>
    <ChatNavigation></ChatNavigation>
    <div class="d-flex justify-content-center align-items-center" v-if="room"><h5>{{ room.name }}</h5></div>
    <ion-content ref="chatcontent" style="--background:ghostwhite">
      <div class="chat-wrapper">
        <div class="chat-entry" :class="message.from_user_id == user.id ? 'right' :'left'"
             v-for="message in currentMessages" :key="message.id">
          <div class="chat-bubble"
               :class="{'chat-bubble-right': message.from_user_id == user.id}">
            <div class="message">{{ message.text }}</div>
            <small class="time">{{ moment(message.created_at).format('dddd HH:mm') }}</small>
          </div>

        </div>
      </div>
    </ion-content>
    <ion-footer collapse="condense" class="chat-footer">
      <ion-toolbar>
        <ion-row>
          <ion-col size="10">
            <ion-input placeholder="Type your message" :value="newMessage"
                       @ionInput="newMessage = $event.target.value;"
                       @keyup.enter="sendMessageToServer(newMessage)"></ion-input>
          </ion-col>
          <ion-col size="2" v-if="newMessage">
            <ion-button class="send-button" @click="sendMessageToServer(newMessage)" fill="clear">
              <ion-icon slot="end" :name="'send-sharp'"></ion-icon>
              Send
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-footer>
  </base-layout>

</template>

<script>
import {defineComponent} from "vue";
import moment from "moment";
import {addIcons} from "ionicons";
import {sendSharp} from "ionicons/icons";
import ChatNavigation from "@/components/chat/ChatNavigation";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

const config = require('@/config');

addIcons({"send-sharp": sendSharp});

export default defineComponent({
  name: "ChatDetail",
  props: ['id'],
  components: {ChatNavigation},
  data() {
    return {
      newMessage: ''

    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('chat', ['chatUser', 'currentRoom', 'currentMessages', 'roomlist']),
    room: function () {
      if (this.roomlist) {
        let tmp = this.roomlist.find(r => r.id == this.$route.params.id);
        console.log('tmp', tmp)
        let findOpponent;
        let findMe;
        let roomToReturn = null;
        if (tmp) {
          if (tmp.user.length == 2) {
            findOpponent = tmp.user.find(u => u.id != this.user.id);
            findMe = tmp.user.find(u => u.id == this.user.id);
          }
          roomToReturn = {
            id: tmp.id,
            updated: tmp.updated_at,
            last_message: tmp.last_message ? tmp.last_message.text : '',
            name: tmp.user.length != 2 ? tmp.name : (findOpponent.firstname + ' ' + findOpponent.lastname),
            avatar_id: findOpponent ? findOpponent.avatar_id : null,
            unread: findMe ? findMe.unreadMessages : 0
          }

        }
        return roomToReturn

      } else {
        return null;
      }

    }
  },
  methods: {
    ...mapActions('chat', ['getMessagesInRoom', 'sendMessage', 'getUnreadMessagesTotalCount', 'getRoomList']),
    ...mapMutations('chat', ['setCurrentRoom', 'setChatUser', 'setCurrentMessages', 'addMessage']),
    sendMessageToServer(message) {
      this.sendMessage(message)
      this.newMessage = '';
    },
  },
  mounted() {
    //let self = this;
    this.chatSocket.subscribe('chat.room.' + config.conference + '.' + this.$route.params.id).bind('chat.message', (message) => {
      this.addMessage(message.message);
      this.$refs.chatcontent.scrollToBottom();

    });
  },
  async created() {
    this.moment = moment;
    await this.getUnreadMessagesTotalCount();
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(to) {
        if (to.name == 'Chat Detail') {
          this.moment = moment;
          this.setCurrentMessages(null)
          this.setCurrentRoom(to.params.id);
          await this.setChatUser(this.user);
          await this.getRoomList();
          await this.getMessagesInRoom();
          await this.getUnreadMessagesTotalCount();
          this.chat = to.params.id;
          this.$refs.chatcontent.scrollToBottom();
        }
      }
    }
  }
});
</script>
<style lang="scss">

.chat-footer {
  border-top: 1px solid #4087FA;
}

.chat-wrapper {
  margin: 0 10px 30px 10px;
  display: flex;
  flex-direction: column;
  clear: both;
  position: relative;


  .chat-entry {
    padding: 10px 10px 20px 10px;
    color: #000;
    overflow: hidden;
    font-size: 0.9rem;
    min-height: 70px;
    display: flex;
    width: 100%;

    .time {
      color: dimgrey;
      margin-top: 10px
    }

    .message {
      padding: 10px;
      border-radius: 7.5px;
      background-color: gainsboro;
    }
  }

  .left {
    justify-content: flex-start;
  }

  .right {
    justify-content: flex-end;
  }

  .chat-bubble {
    min-width: 10%;
    max-width: 65%;
  }

  .chat-bubble-right {
    text-align: right;

    .message {
      background-color: #4087FA;
      color: #fff;
    }
  }

}

.send-button {
  position: absolute;
  bottom: 0;
  right: 5px;
}

</style>